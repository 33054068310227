import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProjectMode } from '@entscheidungsnavi/decision-data';
import { OnDestroyObservable } from '@entscheidungsnavi/tools';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DecisionData } from '@entscheidungsnavi/decision-data';

@Directive({
  selector: '[dtProjectMode]',
  standalone: false,
})
export class ProjectModeDirective implements OnInit {
  // when this is null, the content is always shown
  @Input() dtProjectMode: null | ProjectMode | ProjectMode[];

  private get shouldShow() {
    const pm = this.decisionData.projectMode;
    return this.dtProjectMode == null || (Array.isArray(this.dtProjectMode) ? this.dtProjectMode.includes(pm) : this.dtProjectMode === pm);
  }

  @OnDestroyObservable()
  private onDestroy$: Observable<any>;

  constructor(
    private decisionData: DecisionData,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.decisionData.projectMode$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.check());
  }

  private check() {
    if (this.shouldShow) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
